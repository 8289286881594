@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css'); */
*{
    scroll-behavior: smooth !important;
}
.text-base{
    font-size: 14px !important;
}

.text-xl {
    font-size: 1rem !important;
}